import { createApp } from "vue";
import * as Vue from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import i18n from "./i18n";
import store from "./store";

import io from "socket.io-client";
import axios from "axios";
import { axiosConfig, axiosInitialize } from "@/helpers/http.helper";


import Antd from 'ant-design-vue'

import BootstrapVue3 from "bootstrap-vue-3";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from "maska";
import mitt from "mitt";

import VueFeather from "vue-feather";
import Particles from "particles.vue3";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
const VueScrollTo = require("vue-scrollto");

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
// import * as Icons from "@element-plus/icons";
import "vue-skeletor/dist/vue-skeletor.css";
import "@/assets/scss/config/default/app.scss";
import "./assets/css/lenna.custom.css";
import "./assets/css/lenna.custom.css";
import "./assets/scss/component.custom.scss";
import "@vueform/slider/themes/default.css";
import "vue3-treeselect/dist/vue3-treeselect.css";
import "../node_modules/vue3-emoji-picker/dist/style.css";
import "vue-select/dist/vue-select.css";
import "vue3-circle-progress/dist/circle-progress.css";
import commonMixins from "./helpers/mixins/common.mixin";
import VueObserveVisibility from "vue3-observe-visibility";

// register base components
// import "./components/base/_globals";
import baseComponent from "@/components/base/index";

import { Howl } from "howler";
import { audios } from "./config";

const receiveAudios = audios.map((audio) => {
  return {
    ...audio,
    control: new Howl({
      src: [audio.path],
      volume: 0.5,
    }),
  };
});

const receive = new Howl({
  src: [require("@/assets/music/chinup.mp3")],
  volume: 0.5,
});

import { initFacebookSdk } from "./helpers";

// auth
// import Auth from "./auth.js";
// window.auth = new Auth();

// import "./mainPrototype";

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

const emitter = mitt();

const app = createApp({
  extends: App,
  beforeCreate() {
    initFacebookSdk();
    if(import.meta.env.VITE_ENV !== "local") { 
      // INIT JITSI EXTERNAL API
      let jitsiApi = document.createElement("script");
      jitsiApi.setAttribute("src", "https://meet.lenna.ai/external_api.js");
      document.head.appendChild(jitsiApi);
    }
  },
}).use(Antd)
  .use(i18n)
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVue3)
  .use(Maska)
  .use(Particles)
  .use(vClickOutside)
  .use(VueViewer)
  .use(VueScrollTo)
  .use(Toast)
  .use(ElementPlus)
  .use(VueObserveVisibility)
  .provide("emitter", emitter)
  .mixin(commonMixins)
  .component(VueFeather.type, VueFeather);

// app.config.errorHandler = () => null;
// app.config.warnHandler = () => null;
// .component("infinite-loading", InfiniteLoading);
baseComponent.forEach((item) => {
  app.component(item.name, item);
});

let socket = null;

//rollback
if (localStorage.getItem("user")) {
  const user = JSON.parse(localStorage.getItem("user"));
  socket = io(`${import.meta.env.VITE_SOCKET_URL}`, {
    extraHeaders: {
      userid: user.id,
    },
  });
}

app.provide("socket", socket);
app.provide("audios", audios);
app.provide("receiveAudios", receiveAudios);

app.config.globalProperties.emitter = emitter;
// app.config.globalProperties.$socket = socket;
// app.config.globalProperties.$socket = io(import.meta.env.VITE_SOCKET_URL);
// app.config.globalProperties.$echo = window.Echo;
app.config.globalProperties.$axios = axios.create(axiosConfig);
app.config.globalProperties.$axiosNoLoading = axios.create(axiosConfig);
app.config.globalProperties.$store = store;
app.config.globalProperties.$router = router;
app.config.globalProperties.$swal = Swal;
app.config.globalProperties.$receiveSound = receive;

axiosInitialize(app.config.globalProperties.$axios, store);

app.mount("#app");

export default { app };
