const socialListeningRoute = [
  {
    path: "/:appId/social-listening",
    name: "social-listening",
    component: () =>
      import("@/views/social-listening/pages/SocialListeningMain.vue"),
    meta: { title: "Social Listening" },
  },

  {
    path: "/:appId/social-listening/google-review",
    name: "social-listening-google-review",
    component: () => import("@/views/social-listening/pages/GoogleReview.vue"),
    meta: { title: "Social Listening - Google Review" },
  },
];

export default socialListeningRoute;
